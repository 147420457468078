// WaGen___HASHKEY__d532d1ee_2021-03-18 10:24:43 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
标准CRUD方法：添加记录
*/
export function zwLjfysmx_add(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/add',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：更新记录,需要的主键字段：nf : Integerqdrq : Dateywbh : Longyhbh : Stringjldh : Integerdjfl : Stringydlb : Stringjfdy : Stringdjlb : Stringqyrq : Date
*/
export function zwLjfysmx_update(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/update',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：删除记录,需要的主键字段：nf : Integerqdrq : Dateywbh : Longyhbh : Stringjldh : Integerdjfl : Stringydlb : Stringjfdy : Stringdjlb : Stringqyrq : Date
*/
export function zwLjfysmx_remove(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/remove',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：条件删除
*/
export function zwLjfysmx_selectiveDelete(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/selectiveDelete',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：条件更新
*/
export function zwLjfysmx_selectiveUpdate(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/selectiveUpdate',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：条件插入
*/
export function zwLjfysmx_selectiveAdd(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/selectiveAdd',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：按主键查询，需要的主键字段：nf : Integerqdrq : Dateywbh : Longyhbh : Stringjldh : Integerdjfl : Stringydlb : Stringjfdy : Stringdjlb : Stringqyrq : Date
*/
export function zwLjfysmx_fetch(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/fetch',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：查询所有记录
*/
export function zwLjfysmx_fetchAll(pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/fetchAll',
		method : 'post',
		headers : {
		},
		data : {
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function zwLjfysmx_selectiveGet(entity, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/selectiveGet',
		method : 'post',
		headers : {
		},
		data : {
			param : entity, //ZwLjfysmx
			pagination : pagination
		}
	})
}

/* ---
标准CRUD方法：条件查询
*/
export function zwLjfysmx_selectiveGetOne(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/selectiveGetOne',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
标准CRUD方法：条件计数
*/
export function zwLjfysmx_selectiveCount(entity, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/selectiveCount',
		method : 'post',
		headers : {
		},
		data : {
			param : entity //ZwLjfysmx
		}
	})
}

/* ---
未提供注释
*/
export function zwLjfysmx_getYhsumByJgbmDybmZwyf(jgbm,dybm,zwyf,nf, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/getYhsumByJgbmDybmZwyf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				jgbm : jgbm, //String
				dybm : dybm, //String
				zwyf : zwyf, //Integer
				nf : nf //Integer
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function zwLjfysmx_getDfzdByYhbh(yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/getDfzdByYhbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function zwLjfysmx_getDzfpByYhbh(yhbh, pagination, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/getDzfpByYhbh',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh //String
			},
			pagination : pagination
		}
	})
}


export function zwLjfysmx_getDzfpByYhbhOrNf(nf, yhbh, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/getDzfpByYhbhOrNf',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				nf: nf, // List<Integer>
				yhbh : yhbh //String
			}
		}
	})
}
/* ---
多记录插入
*/
export function zwLjfysmx_insertList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/insertList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ZwLjfysmx>
		}
	})
}

/* ---
多记录更新,需要的主键字段：nf : Integerqdrq : Dateywbh : Longyhbh : Stringjldh : Integerdjfl : Stringydlb : Stringjfdy : Stringdjlb : Stringqyrq : Date
*/
export function zwLjfysmx_updateList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/updateList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ZwLjfysmx>
		}
	})
}

/* ---
多记录删除,需要的主键字段：nf : Integerqdrq : Dateywbh : Longyhbh : Stringjldh : Integerdjfl : Stringydlb : Stringjfdy : Stringdjlb : Stringqyrq : Date
*/
export function zwLjfysmx_deleteList(list, meta) {
	return fetch({
		url : 'psdmsdfzw/sfglschema/ZwLjfysmx/deleteList',
		method : 'post',
		headers : {
		},
		data : {
			param : list //List<ZwLjfysmx>
		}
	})
}

